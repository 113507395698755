<template>
  <div class="dailychemicals">
    <div class="desc box_padding">
      <h3>行业难题</h3>
      <p>
        日化产业规模大，产品同质化严重，市场竞争激烈。防伪方式单一，假冒伪劣产品损害企业形象。
      </p>
      <p>
        日化产品款式多，包装形式多样，发货形式多样。无法做到多批次、少批量生产，如果出现质量问题，无法精准召回。
      </p>
      <div class="desc_img"></div>
      <p>
        产品市场利润空间高，经销渠道庞大，假货窜货严重。同品牌之间相互竞争，打价格战。
      </p>
      <p>日化用品种类繁多，消费者对品牌认可度不高，粉丝留存率低。</p>
    </div>
    <div class="desc_2 box_padding">
      <h3>解决方案</h3>
      <div class="desc_img"></div>
      <p>
        以二维码为入口，以一物一码（一物多码）为产品标识，通过纵向串联、横向关联，协助日化企业建设从包材赋码、产线采集关联、仓储管理、分销终端到消费者的全过程流向管理平台。
      </p>
      <p>提供高尚美观标签赋予产品一物一码，提高产品形象。</p>
      <p>结合工厂生产与发货建立产品多级对应关系，方便物流发货登记。</p>
      <p>多种防伪技术与暗记结合，快速辨别产品真伪。</p>
      <p>通过物流追溯系统管理多级经销商，避免经销窜货。</p>
      <p>
        结合多种营销互动提升用户体验，提升品牌竞争力，帮助企业收集终端大数据。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DailyChemicals",
};
</script>

<style lang="less" scoped>
.dailychemicals {
  .box_padding {
    padding: 50px 30px;
  }

  h3 {
    font-size: 50px;
    font-weight: 700;
  }
  p {
    font-size: 35px;
    color: #333;
    margin: 20px 0;
    text-align: justify;
  }
  span {
    font-size: 35px;
    font-weight: 500;
  }
  img {
    width: 100%;
    border-radius: 10px;
    height: 400px;
    border: 2px solid #f0f0f0;
  }
  .desc_img {
    width: 100%;
    height: 400px;
    border: 2px solid #f0f0f0;
    background: url("~@/assets/img/product/dailychemicals_1.jpg") no-repeat
      right/cover;
    border-radius: 10px;
    margin: 10px 0;
  }
  .desc_2 {
    background-color: #f7f7f7;
    .desc_img {
      height: 250px;
      background: url("~@/assets/img/banner/agriculture.png") no-repeat
        center/cover;
    }
  }
}
</style>